@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Itim';
}

@layer base {
    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-xl;
    }
}

@layer components {
    .modal-mobile-fullscreen {
        height: calc(100vh - 40px - 56px + 16px);
    }

    .form-label {
        @apply text-lg text-black mb-2 block mt-4;
    }

    .reload-animation {
        /* 转动一次 */
        animation: load 1s linear 0 2;
    }
}

@keyframes load{
    0%{
      transform: rotate(0deg)
    }
    100%{
      transform: rotate(360deg)
    }
  }

@layer utilities {
    .flex-center {
        @apply flex justify-center items-center;
    }

    .shadow-top {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .absolute-col-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}